import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "assets/images/icons/check-circle.svg";
import * as Actions from "store/actions/PatientAction";

const useStyles = makeStyles((theme) => ({
  formGroup: {
    marginBottom: 24,
  },
  formGroupTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: "#454D5F",
    marginBottom: 30,
  },
  formFooter: {
    "& .MuiButton-root": {
      height: 56,
      borderRadius: 8,
      fontSize: 16,
      fontWeight: 700,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  radioGroup: {
    padding: "0 10px",
    "& .MuiFormControlLabel-root": {
      margin: 0,
      marginBottom: 24,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingRight: 16,
      },
      "& .MuiTypography-root": {
        color: "#454D5F",
      },
    },
  },
  checkboxGroup: {
    display: "flex",
    flexDirection: "column",
    "& .MuiFormControlLabel-root": {
      margin: 0,
      marginBottom: 32,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingRight: 16,
      },
      "& .MuiTypography-root": {
        fontSize: 16,
        fontWeight: 700,
        color: "#22272F",
      },
    },
  },
  activeButton: {
    background: "#0A5C30",
    border: "1px solid #A6AEBF!important",
    borderRadius: 8,
    color: "#D2F9E4!important",
    "&:hover": {
      background: "#0A5C30",
      boxShadow: "none",
    },
  },
  saveButton: {
    height: 56,
    background: "#003366",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    color: "#FFF",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      background: "#003366",
      boxShadow: "none",
    },
  },
  buttonGroup: {
    marginBottom: 128,
    "& .MuiButton-root": {
      height: 56,
      borderRadius: 8,
      boxShadow: "none",
      border: "1px solid #A6AEBF",
      textTransform: "none",
      fontSize: 16,
      fontWeight: 700,
      color: "#454D5F",
    },
    "& img": {
      marginRight: 16,
    },
  },
  prevButton: {
    background: "#FFF",
    color: "#003366",
    border: "1px solid #A6AEBF",
    "&:hover": {
      background: "#FFF",
    },
  },
}));

const UpdateExclusionCriteria = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    exclusion_criteria_id,
    exclusionCriteriaList,
    updatePatientPersonal
  } = useSelector((state) => {
    return {
      exclusion_criteria_id: state.patient.updatePatient.exclusion?.exclusion_criteria_id,
      exclusionCriteriaList: state.patient.exclusionCriteriaList,
      updatePatientPersonal: state.patient.updatePatient.personal
    };
  });


  const [isClassified, setIsClassified] = useState(updatePatientPersonal.is_classified ? true : false);
  const [isAbove18, setIsAbove18] = useState(updatePatientPersonal.is_above_18);
  const [formData, setFormData] = useState({
    exclusion_criteria_id: exclusion_criteria_id || null,
  });

  const handleChangeAge = (e) => {
    setIsAbove18(JSON.parse(e.target.value));

    if (JSON.parse(e.target.value) === false) {
      setIsClassified(false);
    } else {
      if (formData.exclusion_criteria_id == null) {
        setIsClassified(true);
      }
    }
  };

  const handleChangeCriteria = (e) => {
    setIsClassified(false);
    const selectedVal = e.target.value * 1;

    if (selectedVal === formData.exclusion_criteria_id && isAbove18) {
      setIsClassified(true);
    }

    setFormData({
      ...formData,
      exclusion_criteria_id:
        selectedVal === formData.exclusion_criteria_id ? null : selectedVal,
    });
  };

  const handleChangeQualified = (flag) => {
    //setIsClassified(flag);
  };

  const handleSave = () => {
    dispatch(Actions.setUpdateExclusionData(formData));
    dispatch(Actions.setUpdateClassifiedPersonalData(isClassified));
    dispatch(Actions.setUpdateIsAbove18PersonalData(isAbove18));

    updatePatientPersonal.is_above_18 = isAbove18 ? 1 : 0;
    updatePatientPersonal.is_classified = isClassified ? 1 : 0;

    props.handleSave({ personal: updatePatientPersonal, exclusion: formData });
  };

  const qualified = [
    {
      name: "Zakwalifikowany",
      value: true,
    },
    {
      name: "Niezakwalifikowany",
      value: false,
    },
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>I. Wiek</Typography>
        <Box className={classes.formGroupContent}>
          <RadioGroup
            className={classes.radioGroup}
            value={isAbove18}
          >
            <FormControlLabel
              checked={isAbove18}
              value={true}
              onChange={handleChangeAge}
              control={<Radio color="primary" />}
              label="> 18 lat"
            />
            <FormControlLabel
              checked={!isAbove18}
              value={false}
              onChange={handleChangeAge}
              control={<Radio color="primary" />}
              label="< 18 lat"
            />
          </RadioGroup>
        </Box>
      </Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          II. Kryteria wykluczenia:
        </Typography>
        <Box className={classes.formGroupContent}>
          <Box className={classes.checkboxGroup}>
            {exclusionCriteriaList.map((item) => {
              return (
                <FormControlLabel
                  key={item.id}
                  className={classes.checkbox}
                  control={
                    <Radio
                      checked={item.id === formData.exclusion_criteria_id}
                      value={item.id}
                      onClick={handleChangeCriteria}
                      color="primary"
                    />
                  }
                  label={item.name}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          III. Czy pacjent jest zakwalifikowany do badania naukowego
          CardioCarePack?
        </Typography>
        <Box className={classes.formGroupContent}>
          <Grid container className={classes.buttonGroup} spacing={2}>
            {qualified.map((item, index) => {
              return (
                <Grid xs={12} lg={6} item key={index}>
                  <Button
                    fullWidth
                    onClick={() => handleChangeQualified(item.value)}
                    className={`${isClassified === item.value && classes.activeButton
                      }`}
                    style={{
                      background:
                        !isClassified && item.name === "Niezakwalifikowany"
                          ? "#D26360"
                          : "",
                      color:
                        !isClassified && item.name === "Niezakwalifikowany"
                          ? "#FFF!important"
                          : "",
                    }}
                  >
                    {isClassified === item.value && (
                      <img src={CheckCircleIcon} alt=":( Not Found" />
                    )}
                    {item.name}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Grid container spacing={2} className={classes.formFooter}>
        <Grid item xs={12} lg>
          <Button
            fullWidth
            className={classes.prevButton}
            onClick={props.handleStop}
          >
            Anuluj edycję
          </Button>
        </Grid>
        <Grid item xs={12} lg>
          <Button
            fullWidth
            className={classes.prevButton}
            onClick={props.handleBack}
          >
            Poprzedni krok
          </Button>
        </Grid>
        <Grid item xs={12} lg>
          <Button
            fullWidth
            className={classes.saveButton}
            onClick={handleSave}
          >
            Zapisz
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateExclusionCriteria;
