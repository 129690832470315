import API from "apis/API";

export const setPersonalData = (data) => async (dispatch) => {
  dispatch({ type: "SET_PERSONAL_DATA", payload: data });
};

export const setUpdatePersonalData = (data) => async (dispatch) => {
  dispatch({ type: "SET_UPDATE_PERSONAL_DATA", payload: data });
};

export const setInclusionData = (data) => async (dispatch) => {
  dispatch({ type: "SET_INCLUSION_DATA", payload: data });
};

export const setUpdateInclusionData = (data) => async (dispatch) => {
  dispatch({ type: "SET_UPDATE_INCLUSION_DATA", payload: data });
};

export const setMedicinesData = (data) => async (dispatch) => {
  dispatch({ type: "SET_MEDICINES_DATA", payload: data });
};

export const setUpdateMedicinesData = (data) => async (dispatch) => {
  dispatch({ type: "SET_UPDATE_MEDICINES_DATA", payload: data });
};

export const setAdditionalMedicinesData = (data) => async (dispatch) => {
  dispatch({ type: "SET_ADDITIONAL_MEDICINES_DATA", payload: data });
};

export const setUpdateAdditionalMedicinesData = (data) => async (dispatch) => {
  dispatch({ type: "SET_UPDATE_ADDITIONAL_MEDICINES_DATA", payload: data });
};

export const setExclusionData = (data) => async (dispatch) => {
  dispatch({ type: "SET_EXCLUSION_DATA", payload: data });
};

export const setUpdateExclusionData = (data) => async (dispatch) => {
  dispatch({ type: "SET_UPDATE_EXCLUSION_DATA", payload: data });
};

export const setClassifiedPersonlData = (data) => async (dispatch) => {
  dispatch({ type: "SET_CLASSIFIED_PERSONAL_DATA", payload: data });
};

export const setIsFinishedPersonlData = (data) => async (dispatch) => {
  dispatch({ type: "SET_ISFINISHED_PERSONAL_DATA", payload: data });
};

export const setIsAbove18PersonalData = (data) => async (dispatch) => {
  dispatch({ type: "SET_ISABOVE18_PERSONAL_DATA", payload: data });
};

export const setUpdateClassifiedPersonalData = (data) => async (dispatch) => {
  dispatch({ type: "SET_UPDATE_CLASSIFIED_PERSONAL_DATA", payload: data });
};

export const setUpdateIsAbove18PersonalData = (data) => async (dispatch) => {
  dispatch({ type: "SET_UPDATE_ISABOVE18_PERSONAL_DATA", payload: data });
};

export const setUpdateIsFinishedPersonalData = (data) => async (dispatch) => {
  dispatch({ type: "SET_UPDATE_ISFINISHED_PERSONAL_DATA", payload: data });
};

export const getCriteriaGroupList = () => async (dispatch) => {
  API.inclusionCriteriaGroup.getList().then((res) => {
    dispatch({ type: "GET_CRITERIA_GROUPS", payload: res.data.data });
  });
};

export const getExclusionCriteriaGroup = () => async (dispatch) => {
  API.exclusionCriteriaGroup.getList().then((res) => {
    dispatch({ type: "GET_EXCLUSION_CRITERIAS", payload: res.data.data });
  });
};

export const getMedicineList = () => async (dispatch) => {
  API.medicine.getList().then((res) => {
    dispatch({ type: "GET_MEDICINES", payload: res.data.data });
  });
};

export const getPatientList = (params) => async (dispatch) => {
  API.patient.getList(params).then((res) => {
    dispatch({ type: "GET_PATIENT_LIST", payload: res.data });
  });
};

export const formatCreatePatientData = () => async (dispatch) => {
  dispatch({ type: "FORMAT_CREATE_PATIENT_DATA" });
};

export const formatUpdatePatientData = () => async (dispatch) => {
  dispatch({ type: "FORMAT_UPDATE_PATIENT_DATA" });
};

export const getUnits = () => async (dispatch) => {
  API.unit.getList().then((res) => {
    dispatch({ type: "GET_UNIT_LIST", payload: res.data.data });
  });
};

export const getDoctors = (data) => async (dispatch) => {
  API.doctor.getList(data).then((res) => {
    dispatch({ type: "GET_DOCTOR_LIST", payload: res.data.data });
  });
};

export const initializeUpdatePatientData = (data) => async (dispatch) => {
  let medicines = [];
  let additional_medicines = [];


  data.medicines.map((item) => {
    medicines.push({ medicine_id: item.medicine_id, dose: item.dose * 1 });
  });
  data.additional_medicines.map((item) => {
    additional_medicines.push({ name: item.name, dose: item.dose * 1 });
  });

  let patientData = {
    personal: {
      firstname: data.firstname,
      surname: data.surname,
      pesel: data.pesel,
      address_street: data.address_street,
      address_building: data.address_building,
      address_apartment: data.address_apartment,
      address_postcode: data.address_postcode,
      address_city: data.address_city,
      phone_number: data.phone_number,
      email: data.email,
      weight: data.weight,
      height: data.height,
      project_number: data.project_number,
      is_above_18: data.is_above_18,
      acceptance: data.acceptance,
      is_classified: data.is_classified,
      is_finished: data.is_finished,
      doctor_id : data.doctor_id,
      unit_id : data.unit_id
    },
    inclusion: {
      group_id: data.patient_inclusion_criteria_sub_group?.group_id,
      subgroup_id: data.patient_inclusion_criteria_sub_group?.subgroup_id,
      dose: data.patient_inclusion_criteria_sub_group?.dose * 1,
      interval: data.patient_inclusion_criteria_sub_group?.interval,
    },
    medicines,
    additional_medicines,
    exclusion: {
      exclusion_criteria_id: data.patient_exclusion_criteria?.exclusion_criteria_id,
    },
  };
  dispatch({ type: "INITIALIZE_UPDATE_PATIENT_DATA", payload: patientData });
};
