import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  Modal
} from "@material-ui/core";
import ReactToPrint from "react-to-print";
import API from "apis/API";
import * as Actions from "store/actions/PatientAction";
import CredentialsDocument from "../../documents/CredentialsDocument";
import CheckCircleIcon from "assets/images/icons/check-circle.svg";
import CloseIcon from "assets/images/icons/close.svg";

const useStyles = makeStyles((theme) => ({
  formGroup: {
    marginBottom: 24,
  },
  formGroupTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: "#454D5F",
    marginBottom: 30,
  },
  radioGroup: {
    padding: "0 10px",
    "& .MuiFormControlLabel-root": {
      margin: 0,
      marginBottom: 24,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingRight: 16,
      },
      "& .MuiTypography-root": {
        color: "#454D5F",
      },
    },
  },
  checkboxGroup: {
    display: "flex",
    flexDirection: "column",
    "& .MuiFormControlLabel-root": {
      margin: 0,
      marginBottom: 32,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingRight: 16,
      },
      "& .MuiTypography-root": {
        fontSize: 16,
        fontWeight: 700,
        color: "#22272F",
      },
    },
  },
  activeButton: {
    background: "#0A5C30",
    border: "1px solid #A6AEBF!important",
    borderRadius: 8,
    color: "#D2F9E4!important",
    "&:hover": {
      background: "#0A5C30",
      boxShadow: "none",
    },
  },
  saveButton: {
    height: 56,
    background: "#003366",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    color: "#FFF",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      background: "#003366",
      boxShadow: "none",
    },
  },
  buttonGroup: {
    marginBottom: 128,
    "& .MuiButton-root": {
      height: 56,
      borderRadius: 8,
      boxShadow: "none",
      border: "1px solid #A6AEBF",
      textTransform: "none",
      fontSize: 16,
      fontWeight: 700,
      color: "#454D5F",
    },
    "& img": {
      marginRight: 16,
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      maxWidth: 477,
      padding: theme.spacing(3, 4, 4, 4),
    },
  },
  dialogHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontSize: 20,
      color: "#003366",
      fontWeight: 700,
    },
  },
  dialogContent: {
    padding: theme.spacing(4, 0),
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontSize: 16,
      "&:first-child": {
        color: "#454D5F",
        marginBottom: theme.spacing(2),
      },
      "&:last-child": {
        color: "#6C7689",
      },
    },
  },
  dialogFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
      width: 140,
      height: 56,
      fontFamily: "Lato",
      fontSize: 16,
      fontWeight: 700,
      textTransform: "none",
      borderRadius: 8,
      "&:first-child": {
        color: "#003366",
        border: "1px solid #A6AEBF",
      },
      "&:last-child": {
        background: "#003366",
        color: "#FFF",
        marginLeft: theme.spacing(2),
      },
    },
  },
  closeModalButton: {
    cursor: "pointer",
  },
  printPDF: {
    padding: theme.spacing(4),
  },
}));

const ExclusionCriteria = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const printPDFRef = useRef();
  const printButtonRef = useRef();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {
    is_above_18,
    is_classified,
    exclusion_criteria_id,
    exclusionCriteriaList,
    createPatient,
  } = useSelector((state) => {
    return {
      is_above_18: state.patient.createPatient.personal?.is_above_18,
      is_classified: state.patient.createPatient.personal?.is_classified,
      exclusion_criteria_id:
        state.patient.createPatient.exclusion?.exclusion_criteria_id,
      exclusionCriteriaList: state.patient.exclusionCriteriaList,
      createPatient: state.patient.createPatient,
    };
  });

  const [isClassified, setIsClassified] = useState(is_classified);
  const [filename, setFilename] = useState();
  const [patientLoginInfo, setPatientLoginInfo] = useState({
    login: "",
    password: "",
  });
  const [isAbove18, setIsAbove18] = useState(is_above_18);
  const [formData, setFormData] = useState({
    exclusion_criteria_id: exclusion_criteria_id || null,
  });
  const [showCreds, setShowCreds] = useState(false);
  const { addToast } = useToasts();

  const handleOpen = () => {
    setOpen(true);
    dispatch(Actions.setExclusionData(formData));
    dispatch(Actions.setClassifiedPersonlData(isClassified));
    dispatch(Actions.setIsAbove18PersonalData(isAbove18));
  };

  const handleSave = (needPrint) => {
    setOpen(false);

    createPatient.personal.is_above_18 = isAbove18;
    createPatient.personal.is_classified = isClassified;

    API.patient
      .create(createPatient)
      .then((res) => {
        if (res.data.status === "success") {
          addToast(t("common.patient_created"), {
            appearance: "success",
          });
          if (needPrint)
          {
            setPatientLoginInfo({
              login: res.data.patient.login,
              password: res.data.patient.password,
            });
            // printButtonRef.current.click();
            setShowCreds(true);
            setFilename(
              `${res.data.patient.firstname.toLocaleLowerCase()}_${res.data.patient.surname.toLocaleLowerCase()}`
            );
          }

          history.push("/patient/list");
          dispatch(Actions.formatCreatePatientData());
          
        } else {
          if (res.data.status === "exist_patient") {
            addToast(t("common.exist_patient"), {
              appearance: "error",
            });
          } else
          if (res.data.status === "validation_error")
          {
            addToast(t("common.validation_error"), {
              appearance: "error",
            });
          }
        }
      })
      .catch((err) => {
        addToast("Server errors", {
          appearance: "error",
        });
      });
  };

  const handleChangeAge = (e) => {
    setIsAbove18(JSON.parse(e.target.value));

    if (JSON.parse(e.target.value) === false) {
      setIsClassified(false);
    } else {
      if (formData.exclusion_criteria_id == null) {
        setIsClassified(true);
      }
    }
  };

  const handleChangeCriteria = (e) => {

    setIsClassified(false);
    const selectedVal = e.target.value * 1;
    if (selectedVal === formData.exclusion_criteria_id && isAbove18) {
      setIsClassified(true);
    }

    setFormData({
      ...formData,
      exclusion_criteria_id:
        selectedVal === formData.exclusion_criteria_id ? null : selectedVal,
    });
  };

  const handleChangeQualified = (flag) => {
   // setIsClassified(flag);
  };

  const qualified = [
    {
      name: "Zakwalifikowany",
      value: true,
    },
    {
      name: "Niezakwalifikowany",
      value: false,
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>I. Wiek</Typography>
        <Box className={classes.formGroupContent}>
          <RadioGroup
            className={classes.radioGroup}
            value={isAbove18}
            onChange={handleChangeAge}
          >
            <FormControlLabel
              checked={isAbove18} 
              value={true}
              control={<Radio color="primary" />}
              label="> 18 lat"
            />
            <FormControlLabel
              checked={!isAbove18} 
              value={false}
              control={<Radio color="primary" />}
              label="< 18 lat"
            />
          </RadioGroup>
        </Box>
      </Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          II. Kryteria wykluczenia:
        </Typography>
        <Box className={classes.formGroupContent}>
          <Box className={classes.checkboxGroup}>
            {exclusionCriteriaList.map((item) => {
              return (
                <FormControlLabel
                  key={item.id}
                  className={classes.checkbox}
                  control={
                    <Radio
                      checked={item.id === formData.exclusion_criteria_id}
                      value={item.id}
                      onClick={handleChangeCriteria}
                      color="primary"
                    />
                  }
                  label={item.name}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          III. Czy pacjent jest zakwalifikowany do badania naukowego
          CardioCarePack?
        </Typography>
        <Box className={classes.formGroupContent}>
          <Grid container className={classes.buttonGroup} spacing={2}>
            {qualified.map((item, index) => {
              return (
                <Grid xs={12} lg={6} item key={index}>
                  <Button
                    fullWidth
                    onClick={() => handleChangeQualified(item.value)}
                    className={`${
                      isClassified === item.value && classes.activeButton
                    }`}
                    style={{
                      background:
                        !isClassified && item.name === "Niezakwalifikowany"
                          ? "#D26360"
                          : "",
                      color:
                        !isClassified && item.name === "Niezakwalifikowany"
                          ? "#FFF!important"
                          : "",
                    }}
                  >
                    {isClassified === item.value && (
                      <img src={CheckCircleIcon} alt=":( Not Found" />
                    )}
                    {item.name}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Button fullWidth className={classes.saveButton} onClick={handleOpen}>
        Zarejestruj nowego pacjenta i wygeneruj dane dostępowe
      </Button>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <Box className={classes.dialogHeader}>
          <Typography>Drukowanie danych dostępowych</Typography>
          <img
            src={CloseIcon}
            className={classes.closeModalButton}
            onClick={handleClose}
            alt=":( Not Found"
          />
        </Box>
        <Box className={classes.dialogContent}>
          <Typography>
            Akcja “drukuj” spowoduje wydrukowanie danych dostępowych dla
            pacjenta.
          </Typography>
          <Typography>
            Jeżeli proces zakończy się niepowodzeniem, spróbuj ponownie z
            poziomu karty pacjenta.
          </Typography>
        </Box>
        <Box className={classes.dialogFooter}>
          <Button onClick={()=>{handleSave(false)}}>Nie drukuj</Button>
          <Button onClick={()=>{handleSave(true)}}>Drukuj</Button>
        </Box>
      </Dialog>
      <Box style={{ width: 0, height: 0, overflow: "hidden" }}>
        <ReactToPrint
          trigger={() => (
            <button ref={printButtonRef} style={{ display: "none" }}>
              Drukuj
            </button>
          )}
          content={() => printPDFRef.current}
          documentTitle={filename}
        />
        <Box className={classes.printPDF} ref={printPDFRef}>
          <Typography>Login: {patientLoginInfo.login}</Typography>
          <Typography>Password: {patientLoginInfo.password}</Typography>
        </Box>
      </Box>
      <Modal open={showCreds} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <>
              <CredentialsDocument creds={patientLoginInfo}/>
              <Button
                  style={{
                      left: 10,
                      top: 10,
                      height: 60,
                      width: 50,
                      borderRadius: 40,
                      background: 'white',
                      position: 'absolute'
                  }}
                  //ToDo finish creds generator
                  onClick={() => setShowCreds(false)}
              >
                  <img
                      src={CloseIcon}
                      alt=":( Not Found"
                      style={{cursor: "pointer", color: 'white'}}
                  />
              </Button>
          </>
      </Modal>
    </Box>
  );
};

export default ExclusionCriteria;
