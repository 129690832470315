import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    PDFDownloadLink,
    Link,
    Font
} from "@react-pdf/renderer";
import {
    Box,
} from "@material-ui/core";
import {isMobile, isBrowser} from 'react-device-detect';


Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});

// Create styles
const styles = StyleSheet.create({
    linkContainer: {
        margin: 20,
        width: "100%",
        height: "200px",
        fontFamily: "Roboto",
        padding: 20,
        backgroundColor: "#f5f5f5",
        color: "white",
        borderRadius: 10,
        textAlign: "center",
        paddingTop: 80,
    },
    link : {
        fontSize: 20,
        textDecoration: "none",
        color: "#000"
    },
    page: {
        fontFamily: "Roboto",
        padding: 20,
        backgroundColor: "#f5f5f5",
        color: "white",
    },
    section: {
        margin: 10,
        padding: 10,
    },
    viewer: {
        width: window.innerWidth, //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
});

export default function CredentialsDocument({creds}) {

    return (
        (!isMobile && <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={{
                        flex: 0.2,
                        backgroundColor: '#3f51b5',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Text style={{
                            marginBottom: 20,
                        }}>Dane pacjenta do logowania</Text>
                        <Text style={{fontSize: 16}}>To są wygenerowane dane uwierzytelniające</Text>
                        <Text style={{fontSize: 16}}>
                            do zalogowania się na koncie pacjenta
                        </Text>
                    </View>
                    <View style={{
                        flex: 0.8,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <View
                            style={{
                                backgroundColor: '#e0e0e0',
                                padding: 10,
                                borderRadius: 15,
                            }}
                        >
                            <Text style={{fontSize: 25, color: '#212121', marginBottom: 20}}>Login: {creds.login}</Text>
                            <Text style={{fontSize: 25, color: '#212121'}}>Hasło: {creds.password}</Text>
                        </View>
                        <View style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 50
                        }}>
                            <Text style={{fontSize: 25, color: '#212121', textAlign: 'center'}}>Adres URL do zalogowania:</Text>
                            <Text style={{fontSize: 25, color: '#212121'}}>{process.env.REACT_APP_PATIENT_LOGIN_URL}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>) ||
        (isMobile && <Box style={styles.linkContainer}><PDFDownloadLink style={styles.link} document={
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={{
                        flex: 0.2,
                        backgroundColor: '#3f51b5',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Text style={{
                            marginBottom: 20,
                        }}>Dane pacjenta do logowania</Text>
                        <Text style={{fontSize: 16}}>To są wygenerowane dane uwierzytelniające</Text>
                        <Text style={{fontSize: 16}}>
                            do zalogowania się na koncie pacjenta
                        </Text>
                    </View>
                    <View style={{
                        flex: 0.8,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <View
                            style={{
                                backgroundColor: '#e0e0e0',
                                padding: 10,
                                borderRadius: 15,
                            }}
                        >
                            <Text style={{fontSize: 25, color: '#212121', marginBottom: 20}}>Login: {creds.login}</Text>
                            <Text style={{fontSize: 25, color: '#212121'}}>Hasło: {creds.password}</Text>
                        </View>
                        <View style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 50
                        }}>
                            <Text style={{fontSize: 25, color: '#212121', textAlign: 'center'}}>Adres URL do zalogowania:</Text>
                            <Text style={{fontSize: 25, color: '#212121'}}>{process.env.REACT_APP_PATIENT_LOGIN_URL}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        } fileName="dane-logowania.pdf">Kliknij, aby pobrać plik z danymi</PDFDownloadLink></Box>)
    );
}
